export default {
  "data": [
    {
      "category_id": '30',
      "system_id": 1,
      "category_parent": 0,
      "category_parent_path": "",
      "category_name": "管理中心",
      "category_icon": "meiye-routeicon-calendar",
      "category_route": "",
      "category_component": "LayoutBase",
      "category_params": "",
      "category_type": 1,
      "category_is_show": 1,
      "category_sort": 1,
      "created_at": "2023-04-19 16:31:55",
      "updated_at": "2023-04-19 17:39:53",
      "children": [
        {
          "category_id": '31',
          "system_id": 1,
          "category_parent": 30,
          "category_parent_path": "30",
          "category_name": "商品中心",
          "category_icon": "meiye-routeicon-layers",
          "category_route": "shop",
          "category_component": "LayoutBlock",
          "category_params": "",
          "category_type": 1,
          "category_is_show": 1,
          "category_sort": 1,
          "created_at": "2023-04-19 16:33:28",
          "updated_at": "2023-04-19 17:39:17",
          "children": [
            {
              "category_id": '45',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "储值卡列表",
              "category_icon": "meiye-routeicon-work-copy",
              "category_route": "storedvaluecard",
              "category_component": "storedvaluecard",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-26 23:22:52",
              "updated_at": "2023-04-26 23:29:19",
              "children": [
                {
                  "category_id": '46',
                  "system_id": 1,
                  "category_parent": 45,
                  "category_parent_path": "30,31,45",
                  "category_name": "储值卡列表",
                  "category_icon": "meiye-routeicon-copy",
                  "category_route": "storedvaluecardlist",
                  "category_component": "storedvaluecard",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 0,
                  "category_sort": 1,
                  "created_at": "2023-04-26 23:23:22",
                  "updated_at": "2023-04-26 23:24:37"
                }
              ]
            },
            {
              "category_id": ' 42',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "方案列表",
              "category_icon": "meiye-routeicon-complete",
              "category_route": "plan",
              "category_component": "plan",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-26 16:16:03",
              "updated_at": "2023-04-26 16:16:03",
              "children": [
                {
                  "category_id": '44',
                  "system_id": 1,
                  "category_parent": 42,
                  "category_parent_path": "30,31,42",
                  "category_name": "赠送方案",
                  "category_icon": "meiye-routeicon-data-view",
                  "category_route": "plan_gifts",
                  "category_component": "plan_gifts",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-26 16:17:54",
                  "updated_at": "2023-04-26 16:17:54"
                },
                {
                  "category_id": '43',
                  "system_id": 1,
                  "category_parent": 42,
                  "category_parent_path": "30,31,42",
                  "category_name": "方案列表",
                  "category_icon": "meiye-routeicon-check-item",
                  "category_route": "planlist",
                  "category_component": "plan",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-26 16:17:21",
                  "updated_at": "2023-04-26 16:17:21"
                }
              ]
            },
            {
              "category_id": '39',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "服务列表",
              "category_icon": "meiye-routeicon-layers",
              "category_route": "service",
              "category_component": "service",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-25 16:31:42",
              "updated_at": "2023-04-25 16:31:42",
              "children": [
                {
                  "category_id": ' 41',
                  "system_id": 1,
                  "category_parent": 39,
                  "category_parent_path": "30,31,39",
                  "category_name": "赠送服务",
                  "category_icon": "meiye-routeicon-chart-pie",
                  "category_route": "service_gifts",
                  "category_component": "service_gifts",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-25 16:33:17",
                  "updated_at": "2023-04-25 16:33:17"
                },
                {
                  "category_id": '40',
                  "system_id": 1,
                  "category_parent": 39,
                  "category_parent_path": "30,31,39",
                  "category_name": "服务列表",
                  "category_icon": "meiye-routeicon-column-horizon",
                  "category_route": "servicelist",
                  "category_component": "service",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-25 16:32:32",
                  "updated_at": "2023-04-25 16:32:32"
                }
              ]
            },
            {
              "category_id": '34',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "分类管理",
              "category_icon": "meiye-routeicon-chart-pie",
              "category_route": "type",
              "category_component": "type",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-19 16:37:45",
              "updated_at": "2023-04-19 16:37:45"
            },
            {
              "category_id": '33',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "品牌管理",
              "category_icon": "meiye-routeicon-complete",
              "category_route": "brand",
              "category_component": "brand",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-19 16:36:59",
              "updated_at": "2023-04-19 16:36:59"
            },
            {
              "category_id": '32',
              "system_id": 1,
              "category_parent": 31,
              "category_parent_path": "30,31",
              "category_name": "产品列表",
              "category_icon": "meiye-routeicon-chart-pie",
              "category_route": "management",
              "category_component": "management",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-19 16:34:45",
              "updated_at": "2023-04-19 16:34:58",
              "children": [
                {
                  "category_id": '36',
                  "system_id": 1,
                  "category_parent": 32,
                  "category_parent_path": "30,31,32",
                  "category_name": "赠送产品",
                  "category_icon": "meiye-routeicon-folder-close",
                  "category_route": "give",
                  "category_component": "management_gifts",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-19 17:22:47",
                  "updated_at": "2023-04-25 11:24:41"
                },
                {
                  "category_id": '35',
                  "system_id": 1,
                  "category_parent": 32,
                  "category_parent_path": "30,31,32",
                  "category_name": "产品列表",
                  "category_icon": "meiye-routeicon-chart-pie",
                  "category_route": "managementlist",
                  "category_component": "management",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-19 17:20:08",
                  "updated_at": "2023-04-19 17:20:08"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "category_id": '4',
      "system_id": 1,
      "category_parent": 0,
      "category_parent_path": "",
      "category_name": "数字化管理",
      "category_icon": "meiye-routeicon-chart-pie",
      "category_route": "",
      "category_component": "LayoutBase",
      "category_params": "",
      "category_type": 1,
      "category_is_show": 1,
      "category_sort": 1,
      "created_at": "2023-04-07 14:36:27",
      "updated_at": "2023-04-07 16:13:49",
      "children": [
        {
          "category_id": '16',
          "system_id": 1,
          "category_parent": 4,
          "category_parent_path": "4",
          "category_name": "合伙人",
          "category_icon": "meiye-routeicon-data-view",
          "category_route": "partner",
          "category_component": "LayoutBlock",
          "category_params": "",
          "category_type": 1,
          "category_is_show": 1,
          "category_sort": 1,
          "created_at": "2023-04-11 17:27:01",
          "updated_at": "2023-04-11 17:27:01",
          "children": [
            {
              "category_id": '38',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人账户Ls",
              "category_icon": "meiye-routeicon-discount",
              "category_route": "partnerMenuUserLs",
              "category_component": "partnerMenuUserLs",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-24 10:12:10",
              "updated_at": "2023-04-27 10:59:12"
            },
            {
              "category_id": '37',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人档案Ls",
              "category_icon": "meiye-routeicon-comment",
              "category_route": "partnerMenuFileLs",
              "category_component": "partnerMenuFileLs",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-24 10:11:50",
              "updated_at": "2023-04-27 10:59:31"
            },
            {
              "category_id": '27',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人分红",
              "category_icon": "meiye-routeicon-electronics",
              "category_route": "bonusLs",
              "category_component": "bonusLs",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-18 12:07:24",
              "updated_at": "2023-04-18 12:07:24"
            },
            {
              "category_id": '26',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人收益Ls",
              "category_icon": "meiye-routeicon-chart-pie",
              "category_route": "partnerMenuEarningsLs",
              "category_component": "partnerMenuEarningsLs",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-18 12:06:45",
              "updated_at": "2023-04-18 17:59:59",
              "children": [
                {
                  "category_id": '29',
                  "system_id": 1,
                  "category_parent": 26,
                  "category_parent_path": "4,16,26",
                  "category_name": "合伙人佣金",
                  "category_icon": "meiye-routeicon-discount",
                  "category_route": "commissionLs",
                  "category_component": "commissionLs",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-18 12:08:37",
                  "updated_at": "2023-04-18 18:00:11"
                },
                {
                  "category_id": '28',
                  "system_id": 1,
                  "category_parent": 26,
                  "category_parent_path": "4,16,26",
                  "category_name": "合伙人分红",
                  "category_icon": "meiye-routeicon-chart-pie",
                  "category_route": "bonusLs",
                  "category_component": "bonusLs",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-18 12:08:10",
                  "updated_at": "2023-04-18 12:08:10"
                }
              ]
            },
            {
              "category_id": '22',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人档案",
              "category_icon": "meiye-routeicon-complete",
              "category_route": "partnerMenuFile",
              "category_component": "partnerMenuFile",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-11 17:29:14",
              "updated_at": "2023-04-11 17:29:14"
            },
            {
              "category_id": '21',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人收益",
              "category_icon": "meiye-routeicon-data-view",
              "category_route": "partnerMenuEarnings",
              "category_component": "partnerMenuEarnings",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-11 17:28:53",
              "updated_at": "2023-04-11 17:28:53",
              "children": [
                {
                  "category_id": '25',
                  "system_id": 1,
                  "category_parent": 21,
                  "category_parent_path": "4,16,21",
                  "category_name": "合伙人分红",
                  "category_icon": "",
                  "category_route": "bonus",
                  "category_component": "bonus",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-12 17:22:59",
                  "updated_at": "2023-04-12 17:22:59"
                },
                {
                  "category_id": '24',
                  "system_id": 1,
                  "category_parent": 21,
                  "category_parent_path": "4,16,21",
                  "category_name": "合伙人佣金",
                  "category_icon": "",
                  "category_route": "commission",
                  "category_component": "commission",
                  "category_params": "",
                  "category_type": 1,
                  "category_is_show": 1,
                  "category_sort": 1,
                  "created_at": "2023-04-12 17:22:22",
                  "updated_at": "2023-04-12 17:22:22"
                }
              ]
            },
            {
              "category_id": ' 20',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人账户",
              "category_icon": "meiye-routeicon-discount",
              "category_route": "partnerMenuUser",
              "category_component": "partnerMenuUser",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-11 17:28:35",
              "updated_at": "2023-04-11 18:33:05"
            },
            {
              "category_id": '18',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "合伙人设置",
              "category_icon": "meiye-routeicon-copy",
              "category_route": "partnerMenuSetting",
              "category_component": "partnerMenuSetting",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-11 17:27:52",
              "updated_at": "2023-04-11 17:27:52"
            },
            {
              "category_id": '17',
              "system_id": 1,
              "category_parent": 16,
              "category_parent_path": "4,16",
              "category_name": "概况",
              "category_icon": "meiye-routeicon-chart-pie",
              "category_route": "partnerMenu",
              "category_component": "partnerMenu",
              "category_params": "",
              "category_type": 1,
              "category_is_show": 1,
              "category_sort": 1,
              "created_at": "2023-04-11 17:27:29",
              "updated_at": "2023-04-11 17:27:29"
            }
          ]
        },
      ]
    }
  ],
  "error_code": 0,
  "trace_id": "64531623badbe",
  "data2": {
    "testData": [
      {
        id: '1',
        label: '中国',
        level: '1',
        children: [
          {
            id: '2',
            label: '山东',
            level: '2',
            children: [
              {
                id: '4',
                label: '济南',
                level: '3',
                children: [
                  {
                    id: '5',
                    label: '槐荫区',
                    level: '4'
                  },
                  {
                    id: '6',
                    label: '市中区',
                    level: '4'
                  },
                  {
                    id: '7',
                    label: '历城区',
                    level: '4'
                  },
                  {
                    id: '8',
                    label: '历下区',
                    level: '4'
                  },
                ]
              }
            ]
          },
          {
            id: '3',
            label: '河北',
            level: '2',
            children: [
              {
                id: '9',
                label: '廊坊',
                level: '3'
              }
            ]
          }
        ]
      }
    ]
  }
}